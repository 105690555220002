// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.ValidationInfo_container__XGFRt {\n\tborder: 1px solid grey;\n\tbackground-color: #f0f8ff;\n\tpadding: 8px 8px 8px 8px;\n\tmax-width: 640px;\n}\n\n.ValidationInfo_errors__ZEKDx {\n\tfloat: right;\n\tfont-size: smaller;\n}\n\n.ValidationInfo_green__WHD3x {\n\tfont-weight: bold;\n\tcolor: green;\n}\n\n.ValidationInfo_yellow__1Y6u- {\n\tfont-weight: bold;\n\tcolor: gold;\n}\n\n.ValidationInfo_red__zRbdG {\n\tfont-weight: bold;\n\tcolor: red;\n}\n", "",{"version":3,"sources":["webpack://./src/ValidationInfo.module.css"],"names":[],"mappings":";AACA;CACC,sBAAsB;CACtB,yBAAyB;CACzB,wBAAwB;CACxB,gBAAgB;AACjB;;AAEA;CACC,YAAY;CACZ,kBAAkB;AACnB;;AAEA;CACC,iBAAiB;CACjB,YAAY;AACb;;AAEA;CACC,iBAAiB;CACjB,WAAW;AACZ;;AAEA;CACC,iBAAiB;CACjB,UAAU;AACX","sourcesContent":["\n.container {\n\tborder: 1px solid grey;\n\tbackground-color: #f0f8ff;\n\tpadding: 8px 8px 8px 8px;\n\tmax-width: 640px;\n}\n\n.errors {\n\tfloat: right;\n\tfont-size: smaller;\n}\n\n.green {\n\tfont-weight: bold;\n\tcolor: green;\n}\n\n.yellow {\n\tfont-weight: bold;\n\tcolor: gold;\n}\n\n.red {\n\tfont-weight: bold;\n\tcolor: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ValidationInfo_container__XGFRt",
	"errors": "ValidationInfo_errors__ZEKDx",
	"green": "ValidationInfo_green__WHD3x",
	"yellow": "ValidationInfo_yellow__1Y6u-",
	"red": "ValidationInfo_red__zRbdG"
};
export default ___CSS_LOADER_EXPORT___;
