import React, { useState, useEffect, useRef } from 'react';
import { Button, TextField, Select, MenuItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useOptionalFhir } from './OptionalFhir';
import { verifySHX, SHX_STATUS_NEED_PASSCODE, SHX_STATUS_OK } from './lib/SHX.js';
import { saveDivToPdfFile, saveDivToFHIR, downloadBundleToJSON } from './lib/saveDiv.js';
import { getDeferringCodeRenderer } from './lib/codes.js';
import * as res from './lib/resources.js';
import ValidationInfo from './ValidationInfo.js';
import WrongPatientWarning from './WrongPatientWarning.js';

import Coverage from './Coverage.js';
import ImmunizationHistory from './ImmunizationHistory.js'
import PatientSummary from './PatientSummary.js';

export default function Data({ shx }) {

	const [passcode, setPasscode] = useState(undefined);
	const [shxResult, setShxResult] = useState(undefined);
	const [bundleIndex, setBundleIndex] = useState(0);
	const [showSource, setShowSource] = useState(false);

	const [dcr, setDcr] = useState(getDeferringCodeRenderer());

	const fhir = useOptionalFhir();

	// +--------------------+
	// | renderNeedPasscode |
	// +--------------------+

	const passcodeRef = useRef(null);

	const passcodeClick = async () => {
		setPasscode(passcodeRef.current.value);
		setShxResult(undefined);
	}

	const passcodeKeyDown = (evt) => {
		if (evt.key === 'Enter') passcodeClick();
	}

	const renderNeedPasscode = () => {

		const msg = (passcode && Array.isArray(shxResult.reasons) && shxResult.reasons.length > 0)
			? shxResult.reasons[0]  // Display the specific error message from PasscodeError
			: "This SMART Health Link requires a passcode.";


		return (
			<>
				<br></br>
				<br></br>

				<center>

					<img src="passcode.jpg" alt="SMART Health Card" style={{ width: "150px" }} />
					<div>{msg}</div>

					<div>
						<TextField variant='outlined'
							margin='normal'
							type='password'
							autoComplete='off'
							autoFocus
							inputRef={passcodeRef}
							onKeyDown={passcodeKeyDown}
						/>
					</div>

					<div>
						<Button variant='contained'
							onClick={passcodeClick} >
							Submit
						</Button>
					</div>
				</center>


			</>
		);
	}

	// +-------------+
	// | renderError |
	// +-------------+

	const renderError = (reasons) => {
		let displayReasons = Array.isArray(reasons) ? reasons : [reasons];
		displayReasons = displayReasons.map(reason => reason.replaceAll("Error: ", ''));
		return (<div>{displayReasons.join('; ')}</div>);
	}


	// +---------------------+
	// | renderBundle        |
	// | renderBundleChooser |
	// +---------------------+

	const renderBundle = () => {

		const bundle = shxResult.bundles[bundleIndex];
		const organized = (bundle.contentOK() ? bundle.organized : undefined);

		let elt = undefined;

		if (organized) {

			switch (organized.typeInfo.btype) {

				case res.BTYPE_COVERAGE:
					elt = <Coverage organized={organized} dcr={dcr} />;
					break;

				case res.BTYPE_PS:
					elt = <PatientSummary organized={organized} dcr={dcr} />;
					break;

				case res.BTYPE_IMMUNIZATION:
					elt = <ImmunizationHistory organized={organized} dcr={dcr} />;
					break;

				// >>> ADD MORE RENDERERS HERE <<<

				default:
					elt = <pre><code>{JSON.stringify(bundle.fhir, null, 2)}</code></pre>;
					break;
			}
		}

		return (
			<>
				{renderBundleChooser()}
				<div id="bundle-contents">
					<ValidationInfo bundle={bundle} />
					<WrongPatientWarning organized={organized} />
					{elt}
				</div>
				<div>
					{elt && <Button onClick={() => onSaveFhirServer1(false)}>Save to server 1</Button>}
					{elt && <Button onClick={() => onReadFhirServer1(false)}>Read from server 1</Button>}
					{elt && <Button onClick={() => onSaveFhirServer2(false)}>Save to server 2</Button>}
					{elt && <Button onClick={() => onReadFhirServer2(false)}>Read from server 2</Button>}
				</div>
				<div>
					{elt && <Button onClick={() => onSaveClick(true)}>save to PDF</Button>}
					{elt && fhir && <Button onClick={() => onSaveClick(false)}>save to ehr</Button>}

					{elt && <Button onClick={() => downloadBundleToJSON(bundle.fhir, "fhir-bundle-data")}>Save as FHIR</Button>}
					<Button onClick={() => setShowSource(!showSource)}>source</Button>
					{showSource && <pre><code>{JSON.stringify(bundle, null, 2)}</code></pre>}
				</div>
			</>
		);
	}

	const onSaveFhirServer1 = (toFile) => {

		// defensive because we can show in error cases too
		const baseName = (shxResult &&
			shxResult.bundles &&
			shxResult.bundles[bundleIndex] &&
			shxResult.bundles[bundleIndex].organized &&
			shxResult.bundles[bundleIndex].organized.typeInfo &&
			shxResult.bundles[bundleIndex].organized.typeInfo.label

			? shxResult.bundles[bundleIndex].organized.typeInfo.label
			: "Shared Information");

		const div = document.getElementById("bundle-contents");

		if (toFile) {
			saveDivToPdfFile(div, baseName);
		}
		else {
			saveDivToFHIR(fhir, div, baseName);
		}

		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
		myHeaders.append("Cookie", "AUTH_SESSION_ID_LEGACY=cd3170af-cf18-4691-981a-24c401a2eeaf");

		const urlencoded = new URLSearchParams();
		urlencoded.append("client_id", "fhir-twcore-0.2.2");
		urlencoded.append("client_secret", "6ML54AFeVgMLoRe7cKqshc3171UmD0CI");
		urlencoded.append("grant_type", "client_credentials");

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: urlencoded,
			redirect: "follow"
		};

		fetch("http://172.18.0.58:8080/realms/mitw/protocol/openid-connect/token", requestOptions)
			.then((response) => response.json()) // Convert the response to JSON
			.then((result) => {
				const accessToken = result.access_token; // Access the access_token value
				const expiresIn = result.expires_in; // Access the expires_in value
				console.log("Access Token:", accessToken);
				console.log("Expires In:", expiresIn);

				const myHeaders2 = new Headers();
				myHeaders2.append("Content-Type", "application/fhir+json");
				myHeaders2.append("Authorization", "Bearer " + accessToken);

				const raw = JSON.stringify(shxResult.bundles[0].fhir);

				const requestOptions2 = {
					method: "POST",
					headers: myHeaders2,
					body: raw,
					redirect: "follow"
				};

				fetch("http://172.18.0.53:10002/fhir/Bundle", requestOptions2)
					.then((response) => response.text())
					.then((result) => console.log("RESULT : ", result))
					.catch((error) => console.error(error));
			})
			.catch((error) => console.error(error));
	}
	const onReadFhirServer1 = (toFile) => {

		// defensive because we can show in error cases too
		const baseName = (shxResult &&
			shxResult.bundles &&
			shxResult.bundles[bundleIndex] &&
			shxResult.bundles[bundleIndex].organized &&
			shxResult.bundles[bundleIndex].organized.typeInfo &&
			shxResult.bundles[bundleIndex].organized.typeInfo.label

			? shxResult.bundles[bundleIndex].organized.typeInfo.label
			: "Shared Information");

		const div = document.getElementById("bundle-contents");

		if (toFile) {
			saveDivToPdfFile(div, baseName);
		}
		else {
			saveDivToFHIR(fhir, div, baseName);
		}
		let patient = null;
		for (let i = 0; i < shxResult.bundles[0].fhir.entry.length; i++) {
			if (shxResult.bundles[0].fhir.entry[i].resource.resourceType == "Patient") {
				patient = shxResult.bundles[0].fhir.entry[i].resource;
			}
		}
		let patientId = patient.identifier[0].value;

		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
		myHeaders.append("Cookie", "AUTH_SESSION_ID_LEGACY=cd3170af-cf18-4691-981a-24c401a2eeaf");

		const urlencoded = new URLSearchParams();
		urlencoded.append("client_id", "fhir-twcore-0.2.2");
		urlencoded.append("client_secret", "6ML54AFeVgMLoRe7cKqshc3171UmD0CI");
		urlencoded.append("grant_type", "client_credentials");

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: urlencoded,
			redirect: "follow"
		};

		fetch("http://172.18.0.58:8080/realms/mitw/protocol/openid-connect/token", requestOptions)
			.then((response) => response.json()) // Convert the response to JSON
			.then((result) => {
				const accessToken = result.access_token; // Access the access_token value
				const expiresIn = result.expires_in; // Access the expires_in value
				console.log("Access Token:", accessToken);
				console.log("Expires In:", expiresIn);

				const myHeaders2 = new Headers();
				myHeaders2.append("Content-Type", "application/fhir+json");
				myHeaders2.append("Authorization", "Bearer " + accessToken);

				const requestOptions2 = {
					method: "GET",
					headers: myHeaders2
				};

				fetch("http://172.18.0.53:10002/fhir/Bundle?composition.patient.identifier=" + patientId + "&_sort=-_lastUpdated", requestOptions2)
					.then((response) => response.text())
					.then((result) => console.log("RESULT READ : ", result))
					.catch((error) => console.error(error));
			})
			.catch((error) => console.error(error));
	}
	const onSaveFhirServer2 = (toFile) => {

		// defensive because we can show in error cases too
		const baseName = (shxResult &&
			shxResult.bundles &&
			shxResult.bundles[bundleIndex] &&
			shxResult.bundles[bundleIndex].organized &&
			shxResult.bundles[bundleIndex].organized.typeInfo &&
			shxResult.bundles[bundleIndex].organized.typeInfo.label

			? shxResult.bundles[bundleIndex].organized.typeInfo.label
			: "Shared Information");

		const div = document.getElementById("bundle-contents");

		if (toFile) {
			saveDivToPdfFile(div, baseName);
		}
		else {
			saveDivToFHIR(fhir, div, baseName);
		}

		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

		const urlencoded = new URLSearchParams();
		urlencoded.append("client_id", "fhirmon");
		urlencoded.append("client_secret", "zDjDo6V6lXqA9ayKpoSu7FQzIMcgEa8H");
		urlencoded.append("grant_type", "client_credentials");

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: urlencoded,
			redirect: "follow"
		};

		fetch("http://172.18.0.58:8080/realms/mitw/protocol/openid-connect/token", requestOptions)
			.then((response) => response.json()) // Convert the response to JSON
			.then((result) => {
				const accessToken = result.access_token; // Access the access_token value
				const expiresIn = result.expires_in; // Access the expires_in value
				console.log("Access Token:", accessToken);
				console.log("Expires In:", expiresIn);

				const myHeaders2 = new Headers();
				myHeaders2.append("Content-Type", "application/json");
				myHeaders2.append("Authorization", "Bearer " + accessToken);

				const raw = JSON.stringify(shxResult.bundles[0].fhir);

				const requestOptions2 = {
					method: "POST",
					headers: myHeaders2,
					body: raw,
					redirect: "follow"
				};

				fetch("http://172.18.0.53:10103/api/fhir/Bundle", requestOptions2)
					.then((response) => response.text())
					.then((result) => console.log("RESULT : ", result))
					.catch((error) => console.error(error));
			})
			.catch((error) => console.error(error));

	}
	const onReadFhirServer2 = (toFile) => {

		// defensive because we can show in error cases too
		const baseName = (shxResult &&
			shxResult.bundles &&
			shxResult.bundles[bundleIndex] &&
			shxResult.bundles[bundleIndex].organized &&
			shxResult.bundles[bundleIndex].organized.typeInfo &&
			shxResult.bundles[bundleIndex].organized.typeInfo.label

			? shxResult.bundles[bundleIndex].organized.typeInfo.label
			: "Shared Information");

		const div = document.getElementById("bundle-contents");

		if (toFile) {
			saveDivToPdfFile(div, baseName);
		}
		else {
			saveDivToFHIR(fhir, div, baseName);
		}
		let patient = null;
		for (let i = 0; i < shxResult.bundles[0].fhir.entry.length; i++) {
			if (shxResult.bundles[0].fhir.entry[i].resource.resourceType == "Patient") {
				patient = shxResult.bundles[0].fhir.entry[i].resource;
			}
		}
		let patientId = patient.identifier[0].value;

		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

		const urlencoded = new URLSearchParams();
		urlencoded.append("client_id", "fhirmon");
		urlencoded.append("client_secret", "zDjDo6V6lXqA9ayKpoSu7FQzIMcgEa8H");
		urlencoded.append("grant_type", "client_credentials");

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: urlencoded,
			redirect: "follow"
		};

		fetch("http://172.18.0.58:8080/realms/mitw/protocol/openid-connect/token", requestOptions)
			.then((response) => response.json()) // Convert the response to JSON
			.then((result) => {
				const accessToken = result.access_token; // Access the access_token value
				const expiresIn = result.expires_in; // Access the expires_in value
				console.log("Access Token:", accessToken);
				console.log("Expires In:", expiresIn);

				const myHeaders2 = new Headers();
				myHeaders2.append("Content-Type", "application/fhir+json");
				myHeaders2.append("Authorization", "Bearer " + accessToken);

				const requestOptions2 = {
					method: "GET",
					headers: myHeaders2
				};

				fetch("http://172.18.0.53:10103/api/fhir/Bundle?identifier=ips-123", requestOptions2)
					.then((response) => response.text())
					.then((result) => console.log("RESULT READ : ", result))
					.catch((error) => console.error(error));
			})
			.catch((error) => console.error(error));
	}
	const onSaveClick = (toFile) => {

		// defensive because we can show in error cases too
		const baseName = (shxResult &&
			shxResult.bundles &&
			shxResult.bundles[bundleIndex] &&
			shxResult.bundles[bundleIndex].organized &&
			shxResult.bundles[bundleIndex].organized.typeInfo &&
			shxResult.bundles[bundleIndex].organized.typeInfo.label

			? shxResult.bundles[bundleIndex].organized.typeInfo.label
			: "Shared Information");

		const div = document.getElementById("bundle-contents");

		if (toFile) {
			saveDivToPdfFile(div, baseName);
		}
		else {
			saveDivToFHIR(fhir, div, baseName);
		}

		try {

			let patient = null;
			for (let i = 0; i < shxResult.bundles[0].fhir.entry.length; i++) {
				if (shxResult.bundles[0].fhir.entry[i].resource.resourceType == "Patient") {
					patient = shxResult.bundles[0].fhir.entry[i].resource;
				}
			}


			// const response = fhir.create({
			// 	resource: shxResult.bundles[0].fhir
			// });

			let patientId = patient.id;
			let identifierValue = patient.identifier[0].value;
			let identifierSystem = patient.identifier[0].system;
			let source = patient.meta.source.split('#')[0];
			// patient.meta.source = "http://provider.hie.moh.gov.my";

			const randomNumber = Math.floor(Math.random() * 10);
			let identifier = {
				"system": "http://fhir.hie.moh.gov.my/sid/patient-mrn",
				"value": "IPS-" + randomNumber.toString()
			};
			var items = patient.identifier;
			items.push(identifier);
			patient.identifier = items;

			patient.meta.profile = null;

			console.log("PATIENT ", patient);

			let bodyCreatePatient = {
				"resourceType": "Bundle",
				"id": "ips-bundle",
				"type": "transaction",
				"entry": [
					{
						"fullUrl": "Patient",
						"request": {
							"method": "POST",
							"url": "Patient/" + patientId,
							"ifNoneExist": "?identifier=" + identifierSystem + "|" + identifierValue + "&_source=" + source
						},
						"resource": patient
					}
				]

			};

			const responseCreatePatient = fhir.request({
				url: '', // Leave this empty for Bundles
				method: 'POST',
				body: JSON.stringify(bodyCreatePatient),
				headers: {
					'Content-Type': 'application/fhir+json'
				}
			});

			responseCreatePatient.then(responsePatient => {
				console.log("PATIENT ", responsePatient);
			});

			const responseCreateDocument = fhir.request({
				url: 'Bundle',
				method: 'POST',
				body: JSON.stringify(shxResult.bundles[0].fhir),
				headers: {
					'Content-Type': 'application/fhir+json'
				}
			});
			responseCreateDocument.then(responseDocument => {
				if (responseDocument.resourceType == "Bundle") {
					alert('Save the ehr to the server successful');
				}
				else {
					alert('Save the ehr to the server failed');
				}
			});



		} catch (error) {
			console.error('Error creating patient:', error);
		}
	}

	const onBundleChange = (evt) => {
		setBundleIndex(parseInt(evt.target.value));
	}

	const renderBundleChooser = () => {

		if (shxResult.bundles.length <= 1) return (undefined);

		const elts = [];
		for (const i in shxResult.bundles) {
			elts.push(<MenuItem key={i} value={i}>
				{shxResult.bundles[i].organized.typeInfo.label}
			</MenuItem>);
		}

		return (
			<>
				<span style={{ padding: "0px 8px 0px 6px" }} >
					Section {bundleIndex + 1} of {shxResult.bundles.length}:
				</span>
				<Select
					value={bundleIndex}
					sx={{ mb: 2 }}
					onChange={onBundleChange} >

					{elts}

				</Select>
			</>
		);
	}

	// +-------------+
	// | Main Render |
	// +-------------+

	useEffect(() => {

		verifySHX(shx, passcode)
			.then(result => {
				setShxResult(result);
			})
			.catch(error => {
				// Handle the error appropriately
			});
	}, [shx, passcode]);


	useEffect(() => {
		const checkDcr = async () => { if (await dcr.awaitDeferred()) setDcr(getDeferringCodeRenderer()); }
		checkDcr();
	});

	if (shxResult && shxResult.shxStatus === SHX_STATUS_NEED_PASSCODE) {
		return (renderNeedPasscode());
	}

	if (shxResult && shxResult.shxStatus !== SHX_STATUS_OK) {
		return (renderError(shxResult.reasons));
	}

	if (!shxResult || shxResult.bundles.length === 0) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
				<CircularProgress />
			</div>
		);
	}

	return (renderBundle());
}