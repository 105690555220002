import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import config from './lib/config.js';
import QrScanner from 'qr-scanner';

export default function Photo({ viewData }) {

	const [haveCamera, setHaveCamera] = useState(true);
	const [paused, setPaused] = useState(false);

	const openCameraClick = () => {
		const cameraIdMode = window.sc.getSelectedCamera(config("cameraIdMode"));
		const url = 'captureQR.html#' + escape(cameraIdMode);
		window.openCameraResult = openCameraResult;
		window.open(url, 'captureQR', 'width=500,height=300');
	}

	const unPauseCameraClick = () => { setPaused(false); }
	const pauseCamera = () => { setPaused(true); }

	// called from our popup
	// eslint-disable-next-line
	const openCameraResult = (shx) => {
		viewData(shx);
	}

	// +-----------+
	// | useEffect |
	// +-----------+

	useEffect(() => {

		if (!haveCamera || paused) return;

		const qrScanner = new QrScanner(
			document.getElementById('video'),
			result => viewData(result.data),
			{
				preferredCamera: window.sc.getSelectedCamera(config("cameraIdMode")),
				highlightScanRegion: true,
				highlightCodeOutline: true,
				returnDetailedScanResult: true
			});

		qrScanner.start().then(() => {
			window.sc.maybeShowSwitchCamera(qrScanner, 'switchCamera');
		})
			.catch((err) => {
				console.error(err);
				setHaveCamera(false);
			});

		const millis = config("cameraPauseTimeoutMillis");
		const timerId = setTimeout(pauseCamera, millis);

		return () => {
			clearTimeout(timerId);
			qrScanner.stop();
			qrScanner.destroy();
		}

	}, [haveCamera, setHaveCamera, paused, viewData]);

	// +--------+
	// | render |
	// +--------+

	return (

		<div>

			<h2>Capture Smart Health QR Code Image</h2>
			<p>
				To capture a Smart Health QR code image, follow these steps:
			</p>
			<p>
				1. Prepare Your Device Ensure you have a smartphone or tablet with a camera or a QR code scanner app installed. Make sure your device's camera is functioning properly and is clean.

			</p>
			<p>
				2. Locate the QR Code Find the Smart Health QR code. This could be on a physical document (like a health card or certificate) or displayed on a screen (such as a mobile app or website).

			</p>
			<p>
				3. Position your device so the QR code is clearly visible in the camera frame. Ensure the QR code is well-lit and in focus.	The app should automatically capture and decode the QR code.
			</p>
			<br></br>

			<center>
				{paused &&
					<div>
						<p>Camera paused</p>
						<Button variant='contained' onClick={unPauseCameraClick}>Restart</Button>
					</div>}

				{haveCamera &&
					<>
						<video id='video' style={{ width: '100%', maxWidth: '700px', height: 'auto' }}></video>
						<div id='switchCamera' style={{ display: 'none' }}>
							<Button variant='text' onClick={window.sc.switchCameraClick}>
								Change Camera
							</Button>
						</div>
					</>
				}

				{!haveCamera &&
					<Button variant='contained' onClick={openCameraClick}>Open Camera</Button>}
			</center>
		</div>


	);
}
