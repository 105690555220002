// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".About_container__ePLGO {\n\tdisplay: grid;\n\tgrid-template-columns: 200px 1fr;\n\tgap: 16px;\n\tmax-width: 800px;\n}\n\n\n.About_parentcontainer__B57jV {\n\tdisplay: flex;\n\tjustify-content: center;\n}\n\n.About_bodycontainer__AFhzP {\n\t/* color: white; */\n\tpadding: 25px;\n\tbox-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n\tborder-radius: 8px;\n}\n\n.About_cardImg__AwC9b {\n\tgrid-row: 1;\n\tgrid-column: 1;\n\tmargin-top: 12px;\n}\n\n.About_content__EP8le {\n\tgrid-row: 1;\n\tgrid-column: 2;\n}\n\n.About_deets__O-SsS {\n\tgrid-column: 1 / 3;\n\tgrid-row: 2;\n\tfont-size: smaller;\n}\n\n@media (max-width: 800px) {\n\n\t.About_container__ePLGO {\n\t\tdisplay: block;\n\t}\n\n\t.About_cardImg__AwC9b {\n\t\tdisplay: none;\n\t}\n}", "",{"version":3,"sources":["webpack://./src/About.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,gCAAgC;CAChC,SAAS;CACT,gBAAgB;AACjB;;;AAGA;CACC,aAAa;CACb,uBAAuB;AACxB;;AAEA;CACC,kBAAkB;CAClB,aAAa;CACb,wCAAwC;CACxC,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,cAAc;CACd,gBAAgB;AACjB;;AAEA;CACC,WAAW;CACX,cAAc;AACf;;AAEA;CACC,kBAAkB;CAClB,WAAW;CACX,kBAAkB;AACnB;;AAEA;;CAEC;EACC,cAAc;CACf;;CAEA;EACC,aAAa;CACd;AACD","sourcesContent":[".container {\n\tdisplay: grid;\n\tgrid-template-columns: 200px 1fr;\n\tgap: 16px;\n\tmax-width: 800px;\n}\n\n\n.parentcontainer {\n\tdisplay: flex;\n\tjustify-content: center;\n}\n\n.bodycontainer {\n\t/* color: white; */\n\tpadding: 25px;\n\tbox-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n\tborder-radius: 8px;\n}\n\n.cardImg {\n\tgrid-row: 1;\n\tgrid-column: 1;\n\tmargin-top: 12px;\n}\n\n.content {\n\tgrid-row: 1;\n\tgrid-column: 2;\n}\n\n.deets {\n\tgrid-column: 1 / 3;\n\tgrid-row: 2;\n\tfont-size: smaller;\n}\n\n@media (max-width: 800px) {\n\n\t.container {\n\t\tdisplay: block;\n\t}\n\n\t.cardImg {\n\t\tdisplay: none;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "About_container__ePLGO",
	"parentcontainer": "About_parentcontainer__B57jV",
	"bodycontainer": "About_bodycontainer__AFhzP",
	"cardImg": "About_cardImg__AwC9b",
	"content": "About_content__EP8le",
	"deets": "About_deets__O-SsS"
};
export default ___CSS_LOADER_EXPORT___;
