// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.WrongPatientWarning_warning__6S3dy {\n\tmargin-top: 8px;\n\tborder: 1px solid grey;\n\tbackground-color: #f0f8ff;\n\tpadding: 8px 8px 8px 8px;\n\tmax-width: 640px;\n\tcolor: red;\n\tfont-style: italic;\n}\n\n.WrongPatientWarning_deets__wnWAd {\n}\n", "",{"version":3,"sources":["webpack://./src/WrongPatientWarning.module.css"],"names":[],"mappings":";AACA;CACC,eAAe;CACf,sBAAsB;CACtB,yBAAyB;CACzB,wBAAwB;CACxB,gBAAgB;CAChB,UAAU;CACV,kBAAkB;AACnB;;AAEA;AACA","sourcesContent":["\n.warning {\n\tmargin-top: 8px;\n\tborder: 1px solid grey;\n\tbackground-color: #f0f8ff;\n\tpadding: 8px 8px 8px 8px;\n\tmax-width: 640px;\n\tcolor: red;\n\tfont-style: italic;\n}\n\n.deets {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "WrongPatientWarning_warning__6S3dy",
	"deets": "WrongPatientWarning_deets__wnWAd"
};
export default ___CSS_LOADER_EXPORT___;
