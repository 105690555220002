// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImmunizationHistory_dataTable__l6aOX {\n\tmargin-top: 24px;\n\tmargin-bottom: 24px;\n\tborder-collapse: collapse;\n}\n\n.ImmunizationHistory_dataTable__l6aOX th, .ImmunizationHistory_dataTable__l6aOX td {\n\tmin-width: 100px;\n\ttext-align: left;\n\tvertical-align: top;\n\tborder: 1px solid grey;\n\tpadding: 8px 8px 8px 8px;\n\tfont-size: smaller;\n}\n\n.ImmunizationHistory_innerTable__Ruvjb {\n\tborder-collapse: collapse;\n}\n\n.ImmunizationHistory_innerTable__Ruvjb td, .ImmunizationHistory_innerTable__Ruvjb th {\n\ttext-align: left;\n\tvertical-align: middle;\n\tpadding: 2px 8px 2px 2px;\n\tfont-size: 100%;\n\tborder-top: none;\n\tborder-left: none;\n\tborder-right: none;\n\tborder-bottom: 1px solid lightgray;\n}\n", "",{"version":3,"sources":["webpack://./src/ImmunizationHistory.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,mBAAmB;CACnB,yBAAyB;AAC1B;;AAEA;CACC,gBAAgB;CAChB,gBAAgB;CAChB,mBAAmB;CACnB,sBAAsB;CACtB,wBAAwB;CACxB,kBAAkB;AACnB;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,gBAAgB;CAChB,sBAAsB;CACtB,wBAAwB;CACxB,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,kBAAkB;CAClB,kCAAkC;AACnC","sourcesContent":[".dataTable {\n\tmargin-top: 24px;\n\tmargin-bottom: 24px;\n\tborder-collapse: collapse;\n}\n\n.dataTable th, .dataTable td {\n\tmin-width: 100px;\n\ttext-align: left;\n\tvertical-align: top;\n\tborder: 1px solid grey;\n\tpadding: 8px 8px 8px 8px;\n\tfont-size: smaller;\n}\n\n.innerTable {\n\tborder-collapse: collapse;\n}\n\n.innerTable td, .innerTable th {\n\ttext-align: left;\n\tvertical-align: middle;\n\tpadding: 2px 8px 2px 2px;\n\tfont-size: 100%;\n\tborder-top: none;\n\tborder-left: none;\n\tborder-right: none;\n\tborder-bottom: 1px solid lightgray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dataTable": "ImmunizationHistory_dataTable__l6aOX",
	"innerTable": "ImmunizationHistory_innerTable__Ruvjb"
};
export default ___CSS_LOADER_EXPORT___;
