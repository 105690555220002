// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.TCPFooter_container__v9ViJ {\n\twidth: 100%;\n\tmargin-top: 32px;\n\tborder-top: 1px dotted darkgray;\n\tpadding: 8px 8px 8px 8px;\n\tfont-size: 9px;\n}\n\n.TCPFooter_container__v9ViJ div {\n\tmargin-bottom: 6px;\n}\n\n", "",{"version":3,"sources":["webpack://./src/TCPFooter.module.css"],"names":[],"mappings":";AACA;CACC,WAAW;CACX,gBAAgB;CAChB,+BAA+B;CAC/B,wBAAwB;CACxB,cAAc;AACf;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":["\n.container {\n\twidth: 100%;\n\tmargin-top: 32px;\n\tborder-top: 1px dotted darkgray;\n\tpadding: 8px 8px 8px 8px;\n\tfont-size: 9px;\n}\n\n.container div {\n\tmargin-bottom: 6px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TCPFooter_container__v9ViJ"
};
export default ___CSS_LOADER_EXPORT___;
